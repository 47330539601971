import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {default as axios} from 'axios';

firebase.initializeApp({
    apiKey: "AIzaSyBD36fvHa7ynvVKFbKKGv6wGinTw_0vnYA",
    authDomain: "sidorakh-xyz.firebaseapp.com",
    databaseURL: "https://sidorakh-xyz-default-rtdb.firebaseio.com",
    projectId: "sidorakh-xyz",
    storageBucket: "sidorakh-xyz.appspot.com",
    messagingSenderId: "34122332997",
    appId: "1:34122332997:web:cbee000e93132d46495c2c",
    measurementId: "G-B09XK60VQN"
});

const auth = firebase.auth();
const firestore = firebase.firestore();
const client = axios.create();

client.interceptors.request.use(async(config)=>{
    if (auth.currentUser) {
        config.headers.Authorization = 'Bearer ' + (await auth.currentUser.getIdToken());
    }
    return config;
});

export {
    firebase,
    auth,
    firestore,
    client as axios,
}
