<template>
    <v-app dark>
        <v-app-bar app color="red darken-3" dark>
            <div class="d-flex align-center">
                <v-img alt="Vuetify Logo" class="shrink mr-2" contain :src="require('./assets/shirt.png')" transition="scale-transition" width="40"/>
                <span class="text-h4"> radio </span>
            </div>

            <v-spacer></v-spacer>
        </v-app-bar>

        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: 'App',
        data: () => ({
            
        }),
    };
</script>
