<template>
    <v-container>
        <v-tabs v-model="tab">
            <v-tab>
                Tracks
            </v-tab>
            <v-tab>
                Queue
            </v-tab>
            <v-tab>
                Enqueue
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <keep-alive>
                    <radio-tracks/>
                </keep-alive>
            </v-tab-item>
            <v-tab-item>
                <keep-alive>
                    <radio-queue/>
                </keep-alive>
            </v-tab-item>
            <v-tab-item>
                <keep-alive>
                    <radio-enqueue/>
                </keep-alive>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
    import RadioTracks from '@/components/RadioTracks';
    import RadioQueue from '@/components/RadioQueue';
    import RadioEnqueue from '@/components/RadioEnqueue';
    export default {
        name: 'Home',
        data: ()=>({
            tab:null,
        }),
        components: {
            RadioTracks,
            RadioQueue,
            RadioEnqueue,
        }
        
    }
</script>
