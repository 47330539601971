<template>
    <v-container>
        <v-card-title class="text-h4"> Queue </v-card-title>
        <template v-for="(item,i) of cards">
            <v-card :key="'card-'+i" max-width="600" elevation="6">
                <v-card-title> {{item.title}} </v-card-title>
                <v-card-subtitle> by {{item.artist}} </v-card-subtitle>
                <v-card-text> <a :href="item.url" target="_blank">{{item.url}} <v-icon x-small color="primary"> mdi-open-in-new </v-icon></a> </v-card-text>
                <v-card-text> Progress: {{Math.floor(item.progress*100)}}%</v-card-text>
            </v-card>
        </template>
    </v-container>
</template>

<script>
    import {auth,firestore} from '@/firebase';
    export default {
        name: 'RadioTracks',
        data: () => ({
            cards:[],
            unsub:null,
        }),
        created() {
            this.unsub = firestore.collection('radio-queue').orderBy('time').onSnapshot(snapshot=>{
                for (const change of snapshot.docChanges()) {
                    switch (change.type) {
                        case 'added':
                            this.cards.push({
                                id: change.doc.id,
                                ...change.doc.data(),
                            });
                        break;
                        case 'modified':
                            for (let i=0;i<this.cards.length;i++) {
                                if (this.cards[i].id == change.doc.id) {
                                    this.cards[i].progress = change.doc.data().progress;
                                    this.cards[i].title = change.doc.data().title;
                                    this.cards[i].artist = change.doc.data().artist;
                                    break;
                                }
                            }
                        break;
                        case 'removed':
                            for (let i=0;i<this.cards.length;i++) {
                                if (this.cards[i].id == change.doc.id) {
                                    this.cards.splice(i);
                                    break;
                                }
                            }
                        break;
                    }
                }
            })
        }

    }
</script>
