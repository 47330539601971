<template>
    <v-container>
        <v-card>
            <v-card-title> Log in </v-card-title>
            <v-card-text>
                <v-text-field label="Email" v-model="email" type="email"/>
                <v-text-field label="Password" v-model="password" type="password"/>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" @click="login"> Log in </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import {auth} from '../firebase';
    export default {
        name: 'Login',
        data:()=>({
            email: null,
            password: null,
        }),
        methods: {
            async login(){
                try {
                    await auth.signInWithEmailAndPassword(this.email,this.password);
                    this.$router.push('/home');
                } catch(e) {
                    alert(e);
                }



            }
        }
    }
</script>
