<template>
    <v-container>
        Tracks
    </v-container>
</template>

<script>
    export default {
        name: 'RadioTracks',
        data: () => ({
        
        }),
    }
</script>
