<template>
    <v-container>
        <v-card>
            <v-card-title> Add to queue </v-card-title>
            <v-card-text>
                <v-text-field v-model="url" type="url" label="URL"/>
                <v-text-field v-model="title" type="text" label="Title"/>
                <v-text-field v-model="artist" type="text" label="Artist"/>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="enqueue" color="primary"> Enqueue </v-btn>
                <v-btn @click="get_metadata" color="primary"> Fetch metadata </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import {axios,firestore} from '@/firebase'
    export default {
        name: 'RadioTracks',
        data: () => ({
            url: null,
            title: null,
            artist: null,
        }),
        created(){
        },
        methods: {
            async enqueue(){
                await firestore.collection('radio-queue').doc().set({
                    url: this.url,
                    title: this.title,
                    artist: this.artist,
                    time: Date.now(),
                    progress: 0,
                    state: 'Pending',
                });
                this.url = '';
                this.title = '';
                this.artist = '';
            },
            async get_metadata(){
                const result = await axios.get('/api/metadata',{params: {url: this.url}});
                if (result.data.status == 'success') {
                    this.title = result.data.title;
                    this.artist = result.data.artist;
                }
            }
        }
    }
</script>
