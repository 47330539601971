import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './views/Login.vue';
import Home from './views/Home.vue';
import {auth} from './firebase';

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Login', component: Login },
    { path: '/home', name: 'Home', component: Home },
];

const router = new VueRouter({
    routes
});

router.beforeEach((to,from,next)=>{
    const unsub = auth.onAuthStateChanged(user=>{
        unsub();
        if (user == null) {
            if (to.name != 'Login') return next('/');
        }
        next();
    })
})

export default router;
